<template>
  <div class="carousel slide border_05 ">
    <div
      class="carousel-inner grayBg"
      v-if="filteredItems.buildings_gallery.length != 0"
    >
      <div class="carousel-item active">
        <transition name="fade" mode="out-in">
          <img
            v-for="item in filteredItems.buildings_gallery.slice(
              current,
              current + 1
            )"
            :src="item.path"
            class="d-block mx-auto"
            alt="photo"
            style="height: auto; width: 100%"
            :key="'img' + item.id"
          />
        </transition>
        <div
          :class="
            numberItem == 0
              ? 'd-none d-md-block carousel-caption_02'
              : 'd-none d-md-block carousel-caption'
          "
        >
          <h5
            :class="
              numberItem == 0
                ? 'border_radius_03 blueBpmkBg pt-1 pb-1'
                : 'border_radius_02 blueBpmkBg pt-1 pb-1'
            "
          >
            {{ filteredItems.name }}
          </h5>
        </div>
      </div>
    </div>
    <a
      class="carousel-control-prev"
      role="button"
      v-if="filteredItems.buildings_gallery.length > 1"
    >
      <svg
        width="16.491mm"
        height="16.491mm"
        version="1.1"
        viewBox="0 0 16.491 16.491"
        xmlns="http://www.w3.org/2000/svg"
        @click="prevSlide"
      >
        <defs>
          <clipPath id="clipPath1081">
            <path d="m0 0h900v900h-900z" />
          </clipPath>
        </defs>
        <g transform="translate(-20.481 -86.915)">
          <g transform="matrix(-.35278 0 0 .35278 322.69 -2.6875)">
            <g clip-path="url(#clipPath1081)" opacity=".9">
              <g>
                <path
                  d="m856.65 277.37c0 12.906-10.464 23.371-23.375 23.371-12.906 0-23.371-10.465-23.371-23.371 0-12.91 10.465-23.375 23.371-23.375 12.911 0 23.375 10.465 23.375 23.375"
                  fill="#ededed"
                  fill-opacity=".6"
                />
                <path
                  d="m829.91 265.56v6.558h-13.711v10.5h13.711v6.559l10.227-5.906 10.23-5.903-10.23-5.906z"
                  fill-opacity=".7"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      role="button"
      v-if="filteredItems.buildings_gallery.length > 1"
    >
      <svg
        width="16.491mm"
        height="16.491mm"
        version="1.1"
        viewBox="0 0 16.491 16.491"
        xmlns="http://www.w3.org/2000/svg"
        @click="nextSlide"
      >
        <defs>
          <clipPath id="clipPath1081">
            <path d="m0 0h900v900h-900z" />
          </clipPath>
        </defs>
        <g transform="translate(-20.481 -86.915)">
          <g transform="matrix(.35278 0 0 .35278 -265.24 -2.6875)">
            <g clip-path="url(#clipPath1081)" opacity=".9">
              <g>
                <path
                  d="m856.65 277.37c0 12.906-10.464 23.371-23.375 23.371-12.906 0-23.371-10.465-23.371-23.371 0-12.91 10.465-23.375 23.371-23.375 12.911 0 23.375 10.465 23.375 23.375"
                  fill="#ededed"
                  fill-opacity=".6"
                />
                <path
                  d="m829.91 265.56v6.558h-13.711v10.5h13.711v6.559l10.227-5.906 10.23-5.903-10.23-5.906z"
                  fill-opacity=".7"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>

      <span class="sr-only">Next</span>
    </a>
    <div
      class="carousel-inner grayBg text-white text-center"
      v-if="filteredItems.buildings_gallery.length == 0"
    >
      <svg
        xmlns:dc="http://purl.org/dc/elements/1.1/"
        xmlns:cc="http://creativecommons.org/ns#"
        xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg"
        id="svg2"
        viewBox="0 0 500.00001 500.00001"
        version="1.1"
        height="18rem"
        width="18rem"
        fill="currentColor"
      >
        <metadata id="metadata24">
          <rdf:RDF>
            <cc:Work rdf:about="">
              <dc:format>image/svg+xml</dc:format>
              <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
              <dc:title></dc:title>
            </cc:Work>
          </rdf:RDF>
        </metadata>
        <defs id="defs22" />
        <g id="g4" transform="translate(0 -552.36)">
          <g id="g6" transform="translate(0,30)">
            <g id="g8" transform="translate(-2.0824 -17.653)">
              <g id="g10" transform="translate(31.003 33.677)">
                <path
                  id="path12"
                  d="m91.797 896.7h313.42s-86.961-176.96-104.13-180.36c-17.172-3.4002-92.233 117.99-92.233 117.99s-41.09-77.418-57.755-75.466c-16.664 1.9513-59.295 137.84-59.295 137.84z"
                />
                <circle
                  id="circle14"
                  opacity=".97"
                  r="29.262"
                  cy="697.26"
                  cx="190.37"
                />
              </g>
              <path
                id="path16"
                d="m79.508 666.03v300h400v-300h-400zm25 20h350v260h-350v-260z"
              />
            </g>
            <path
              id="path18"
              d="m25 50v300h40v-20h-15v-260h350v15.365h25v-35.365h-400z"
              transform="translate(0 552.36)"
            />
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: ["filteredItems", "numberItem"],
  data() {
    return {
      current: 0,
      width: 800,
      timer: 0,
    };
  },
  methods: {
    nextSlide: function () {
      this.current++;
      if (this.current >= this.filteredItems.buildings_gallery.length)
        this.current = 0;
     // this.resetPlay();
    },
    prevSlide: function () {
      this.current--;
      if (this.current < 0)
        this.current = this.filteredItems.buildings_gallery.length - 1;
    //  this.resetPlay();
    },
    selectSlide: function (i) {
      this.current = i;
    //  this.resetPlay();
    },
    resetPlay: function () {
      clearInterval(this.timer);
    //  this.play();
    },
    play: function () {
      let app = this;
      this.timer = setInterval(function () {
        app.nextSlide();
      }, 5000);
    },
  },
  created: function () {
  //  this.play();
  },
};
</script>

<style lang="scss" scoped>
</style>