<template>
  <div class="row pb-2" style="padding-left: 30px; padding-right: 0px">
    <div
      class="col-12 p-0 border_01 d-lg-none d-xl-none"
      v-for="(item, index) in filteredItems.slice(
        startPagePaginatorMobile,
        endPagePaginatorMobile
      )"
      :key="'caraousel' + item.id"
    >
      <div class="row-fluid">
        <carousel-item
          :filteredItems="item"
          :numberItem="index"
        ></carousel-item>
      </div>
      <div
        class="row pt-1 pb-1 pl-2 pr-2"
        v-if="index == 0"
      >
        <div class="col-12" v-if="userCardBoolCatalog_01 == false && !Loading_01">
          <h4 class="blueBpmkText">
            <b>{{ item.name }}</b>
          </h4>
          <h5 class="blueBpmkText">{{ item.place }}</h5>
          <h5 class="yellowBpmkText">{{ item.type.name }}</h5>
          <h5 class="pb-2 border-bottom">
            {{ item.purpose.name }}
          </h5>
                    <h5 class="">Pôdorys objektu</h5>
          <p class="grayText pb-2 border-bottom">
            <img
              :src="item.poster[0].path"
              alt="podoris"
              class="img-fluid"
              v-if="item.poster.length > 0"
              style="height: 8rem; width: auto"
              @click="showModal=true"
            />
            <svg
              xmlns:dc="http://purl.org/dc/elements/1.1/"
              xmlns:cc="http://creativecommons.org/ns#"
              xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
              xmlns:svg="http://www.w3.org/2000/svg"
              xmlns="http://www.w3.org/2000/svg"
              id="svg2"
              viewBox="0 0 500.00001 500.00001"
              version="1.1"
              width="100%"
              height="100%"
              fill="currentColor"
              v-if="item.poster.length == 0"
            >
              <metadata id="metadata24">
                <rdf:RDF>
                  <cc:Work rdf:about="">
                    <dc:format>image/svg+xml</dc:format>
                    <dc:type
                      rdf:resource="http://purl.org/dc/dcmitype/StillImage"
                    />
                    <dc:title></dc:title>
                  </cc:Work>
                </rdf:RDF>
              </metadata>
              <defs id="defs22" />
              <g id="g4" transform="translate(0 -552.36)">
                <g id="g6" transform="translate(0,30)">
                  <g id="g8" transform="translate(-29.508,-43.67)">
                    <g id="g10" transform="translate(31.003,33.677)">
                      <path
                        id="path12"
                        d="m 91.797,896.7 313.42,0 c 0,0 -86.961,-176.96 -104.13,-180.36 -17.172,-3.4002 -92.233,117.99 -92.233,117.99 0,0 -41.09,-77.418 -57.755,-75.466 -16.664,1.9513 -59.295,137.84 -59.295,137.84 z"
                      />
                      <circle
                        style="opacity: 0.97000002"
                        id="circle14"
                        r="29.261999"
                        cy="697.26001"
                        cx="190.37"
                      />
                    </g>
                    <path
                      id="path16"
                      d="m 79.508,666.03 0,300 400,0 0,-300 -400,0 z m 25,20 350,0 0,260 -350,0 0,-260 z"
                    />
                  </g>
                </g>
              </g>
            </svg>
            <modal-poster-item :showModal="showModal" @hide-modal="showModal=$event" :filteredItems="item.poster"></modal-poster-item>
          </p>

          <h5 class="">Správca</h5>
          <p class="pb-2 border-bottom">
            {{ item.contact.title }} {{ item.contact.name }}
            {{ item.contact.surname }} <br />
            {{ item.contact.mobile }} <br />
            {{ item.contact.email }}
          </p>
          <h5 class="">Informácie o budove</h5>
          <p>
            <table class="table table-borderless">
              <tbody>
                <tr>
                  <td  class="p-0">Celková výmera objektu</td>
                  <td  class="p-0">{{ item.area }}</td>
                  </tr>
              </tbody>
            </table>
          </p>
                    <p class="text-center">
            <svg
              width="3rem"
              height="3rem"
              viewBox="0 0 16 16"
              class="bi bi-info-circle pointerOn"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              v-if="item.description"
              @click="UserCardFunction_01(item)"
            >
              <path
                fill-rule="evenodd"
                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
              />
              <path
                d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z"
              />
              <circle cx="8" cy="4.5" r="1" />
            </svg>
          </p>
        </div>
        <div class="col-12" v-if="userCardBoolCatalog_01 == true && !Loading_01">
          <p v-html="item.description">
                </p>
                <p class="text-center">
                  <svg
                    width="3rem"
                    height="3rem"
                    viewBox="0 0 16 16"
                    class="bi bi-info-circle pointerOn"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    v-if="item.description"
                    @click="userCardDetailFalse_01(false)"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                    />
                    <path
                      d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z"
                    />
                    <circle cx="8" cy="4.5" r="1" />
                  </svg>
                </p>    
        </div>
        <loading-item v-if="Loading_01"></loading-item>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingItem from "../components/LoadingItem";
import CarouselItem from "../components/CarouselItem";
import ModalPosterItem from "../components/ModalPosterItem";
export default {
  components: {
    LoadingItem,
    CarouselItem,
    ModalPosterItem
  },
  props: [
    "filteredItems",
    "startPagePaginatorMobile",
    "endPagePaginatorMobile",
    "userCardBool"
  ],

  data() {
    return {
      userID_01: "",
      userID_02: "",
      userCardBoolCatalog_01: false,
      userCardBoolCatalog_02: false,
      Loading_01: false,
      Loading_02: false,
      showModal: false
    };
  },
  methods: {
    LoadingFunction_01(value) {
      this.Loading_01 = true;
      setTimeout(() => {
        this.Loading_01 = false;
      }, value);
    },
    LoadingFunction_02(value) {
      this.Loading_02 = true;
      setTimeout(() => {
        this.Loading_02 = false;
      }, value);
    },
    //beforeEnter: function (el) {},
    UserCardFunction_01($id) {
      this.LoadingFunction_01(500);
      // console.log($id);
      this.userID_01 = $id;
      this.userCardBoolCatalog_01 = true;
      this.$emit("catalog-description", true);
    },
    userCardDetailFalse_01() {
      this.LoadingFunction_01(500);
      this.userCardBoolCatalog_01 = false;
    },
    UserCardFunction_02($id) {
      this.LoadingFunction_02(500);
      // console.log($id);
      this.userID_02 = $id;
      this.userCardBoolCatalog_02 = true;
      this.$emit("catalog-description", true);
    },
    userCardDetailFalse_02() {
      this.LoadingFunction_02(500);
      this.userCardBoolCatalog_02 = false;
    }
  },
  watch: {
    userCardBool(newValue) {
      //console.log(newValue);
      if (newValue == false) {
        this.userCardBoolCatalog_01 = false;
        this.userCardBoolCatalog_02 = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
td {
  padding-top: 0;
  padding-bottom: 0;
}
.border-right {
  border-right: 1px solid #f0ab17 !important;
}
.border-left {
  border-left: 1px solid #f0ab17 !important;
}
</style>