import { render, staticRenderFns } from "./ModalCarouselItem.vue?vue&type=template&id=83983486&scoped=true&"
import script from "./ModalCarouselItem.vue?vue&type=script&lang=js&"
export * from "./ModalCarouselItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83983486",
  null
  
)

export default component.exports