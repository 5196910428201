<template>
  <transition name="fade" mode="out-in">
    <div
      class="modal fade show"
      id="exampleModalCenteredScrollable"
      tabindex="-1"
      aria-labelledby="exampleModalCenteredScrollableTitle"
      style="display: block"
      aria-modal="true"
      role="dialog"
      v-if="showModal"
    >
      <div
        class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
      >
        <div class="modal-content rounded-0">
          <!--div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenteredScrollableTitle">
              Modal title
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div-->
          <div class="modal-body p-0">
            <modal-carousel-item
              :filteredItems="filteredItems"
              :numberItem="1"
            ></modal-carousel-item>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn blueBpmkBg text-white rounded-0"
              @click="hideModal"
            >
              Zavrieť
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--div
      class="modal fade show"
      id="exampleModalCenteredScrollable"
      tabindex="-1"
      aria-labelledby="exampleModalCenteredScrollableTitle"
      style="display: block; padding-right: 17px"
      aria-modal="true"
      role="dialog"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenteredScrollableTitle">
              Modal title
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <p>
              Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
              dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
              ac consectetur ac, vestibulum at eros.
            </p>
            <p>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur
              et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
              auctor.
            </p>
            <p>
              Aenean lacinia bibendum nulla sed consectetur. Praesent commodo
              cursus magna, vel scelerisque nisl consectetur et. Donec sed odio
              dui. Donec ullamcorper nulla non metus auctor fringilla.
            </p>
            <p>
              Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
              dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
              ac consectetur ac, vestibulum at eros.
            </p>
            <p>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur
              et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
              auctor.
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div>
        </div>
      </div>
    </div-->
    <!--div class="modal-mask modal-dialog-scrollable" v-if="showModal">
      <div class="modal-wrapper">
        <div class="modal-container p-0">
          <div class="modal-body p-0">
              <modal-carousel-item
                :filteredItems="filteredItems"
                :numberItem="1"
              ></modal-carousel-item>
         </div>

          <div class="modal-footer">
              default footer
              <button class="modal-default-button" @click="hideModal">
                OK
              </button>
          </div>
        </div>
      </div>
    </div-->
  </transition>
</template>

<script>
import ModalCarouselItem from "../components/ModalCarouselItem.vue";
export default {
  components: { ModalCarouselItem },
  props: ["showModal", "filteredItems"],
  data() {
    return {
      show: false,
      //showModal: false,
    };
  },
  methods: {
    hideModal() {
      this.$emit("hide-modal", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 95%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
</style>