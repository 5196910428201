<template>
  <transition name="fade" mode="out-in">
    <div
      class="modal fade show"
      id="exampleModalCenteredScrollable"
      tabindex="-1"
      aria-labelledby="exampleModalCenteredScrollableTitle"
      style="display: block"
      aria-modal="true"
      role="dialog"
      v-if="showModal"
    >
      <div
        class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
      >
        <div class="modal-content rounded-0">
          <div class="modal-body p-0">
            <modal-poster-carousel-item
              :filteredItems="filteredItems"
              :numberItem="1"
            ></modal-poster-carousel-item>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn blueBpmkBg text-white rounded-0"
              @click="hideModal"
            >
              Zavrieť
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ModalPosterCarouselItem from "../components/ModalPosterCarouselItem.vue";
export default {
  components: { ModalPosterCarouselItem },
  props: ["showModal", "filteredItems"],
  data() {
    return {
      show: false,
      //showModal: false,
    };
  },
  methods: {
    hideModal() {
      this.$emit("hide-modal", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 95%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
</style>