<template>
  <transition name="fade" mode="out-in" v-cloak>
    <div class="row mb-5" v-if="!Loading" key="main1">
      <div class="col-md-8">
        <label class="sr-only" for="inlineFormInputGroup">Search</label>
        <div class="input-group mb-2">
          <div class="input-group-prepend pointerOn">
            <div
              class="input-group-text rounded-0 bg-transparent border_03 h-30"
              @click="reset"
            >
              <span class="text-danger"><b>X</b> </span>
            </div>
          </div>
          <input
            type="text"
            class="form-control border_04 h-30"
            v-model="searchInput"
            @click="userCardBool = false"
            id="inlineFormInputGroup"
            placeholder="Vyhladavanie"
          />
          <div class="input-group-append pointerOn">
            <div
              class="input-group-text rounded-0 bg-transparent border_02 h-30"
            >
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-search"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
                />
                <path
                  fill-rule="evenodd"
                  d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <select
          class="custom-select custom-select-sm rounded-0 border_01"
          v-model="rootName"
          @change="SelectMenu"
        >
          <option value="undefined" selected>Všetky</option>
          <option :value="nav.id" v-for="nav in NavigatorMenu" :key="nav.id">
            {{ nav.name }}
          </option>
        </select>
      </div>
      <transition
        :name="nextPageAnimation > 0 ? 'slide4' : 'slide5'"
        mode="out-in"
        class="col-12 p-0"
      >
        <catalog-pc-item
          :filteredItems="filteredItems"
          :startPagePaginator="startPagePaginator"
          :endPagePaginator="endPagePaginator"
          :userCardBool="userCardBool"
          v-if="!SlideAnimation"
          @catalog-description="userCardBool = $event"
          key="1"
          class="container"
        ></catalog-pc-item>
      </transition>
      <transition
        :name="nextPageAnimation > 0 ? 'slide4' : 'slide5'"
        mode="out-in"
        class="col-12 p-0"
      >
        <catalog-mobile-item
          :filteredItems="filteredItems"
          :startPagePaginatorMobile="startPagePaginatorMobile"
          :endPagePaginatorMobile="endPagePaginatorMobile"
          :userCardBool="userCardBool"
          v-if="!SlideAnimation"
          @catalog-description="userCardBool = $event"
          key="1"
          class="container"
        ></catalog-mobile-item>
      </transition>
      <footer-item
        :filteredItems="filteredItems.length"
        :startPage="startPage"
        :endPage="allPages"
        :startPageMobile="startPageMobile"
        :endPageMobile="allPagesMobile"
        @next-page-mobile="nextPageMobile($event)"
        @next-page="nextPagePC($event)"
      ></footer-item>
    </div>
    <loading-item v-if="Loading" key="main2"></loading-item>
  </transition>
</template>

<script>
import axios from "axios";
import LoadingItem from "../components/LoadingItem";
import FooterItem from "../components/FooterItem";
import CatalogPcItem from "../components/CatalogPcItem";
import CatalogMobileItem from "../components/CatalogMobileItem.vue";
export default {
  props: ["nameApiRequest"],
  components: {
    LoadingItem,
    FooterItem,
    CatalogPcItem,
    CatalogMobileItem,
  },
  data() {
    return {
      userCardBool: false,
      Loading: true,
      apiRequest: [],
      searchInput: "",
      rootName: "",
      SlideAnimation: false,
      NavigatorMenu: "",

      //pc paginator
      allPages: 0,
      startPage: 1,
      startPagePaginator: 0,
      endPagePaginator: 2,

      //mobile paginator
      allPagesMobile: 0,
      startPageMobile: 1,
      startPagePaginatorMobile: 0,
      endPagePaginatorMobile: 1,
      nextPageAnimation: 0,

      //nameApiRequest: "apartments/section/1",
    };
  },
  mounted() {
    this.BaseRequest();
  },
  methods: {
    SelectMenu() {
      if (this.rootName > 0) {
        return this.$router.push("/nebyty/" + this.rootName);
        console.log(this.rootName);
      } else {
        return this.$router.push("/");
      }
    },
    ResetPaginator() {
      //pc
      this.allPages = Math.ceil(this.filteredItems.length / 2);
      this.startPage = 1;
      this.endPagePaginator = 2;
      this.startPagePaginator = 0;

      //mobile
      this.allPagesMobile = Math.ceil(this.filteredItems.length / 1);
      this.startPageMobile = 1;
      this.startPagePaginatorMobile = 0;
      this.endPagePaginatorMobile = 1;
    },
    LoadingFunction(value) {
      setTimeout(() => {
        this.Loading = false;
        this.SlideAnimation = false;
      }, value);
    },
    nextPagePC(value) {
      //this.Loading = true;
      this.LoadingFunction(1000);
      this.SlideAnimation = true;
      this.nextPageAnimation = value;
      //pc
      this.startPage = this.startPage + value;
      this.startPagePaginator = this.startPagePaginator + value * 2;
      this.endPagePaginator = this.endPagePaginator + value * 2;
    },
    nextPageMobile(value) {
      //this.Loading = true;
      this.LoadingFunction(1000);
      this.SlideAnimation = true;
      this.nextPageAnimation = value;
      //mobile
      this.startPageMobile = this.startPageMobile + value;
      this.startPagePaginatorMobile = this.startPagePaginatorMobile + value * 1;
      this.endPagePaginatorMobile = this.endPagePaginatorMobile + value * 1;
    },

    BaseRequest() {
      this.userCardBool = false;
      this.rootName = this.nameApiRequest;
      if (this.nameApiRequest) {
        axios
          .get("https://apiapp.bpmk.sk/api/types")
         //.get("http://localhost/OctoberCMS/bpmk_app_api_04/api/types")
          .then(({ data }) => {
            this.NavigatorMenu = data;
          })
          .catch((error) => {
            return this.$router.push("/home/404");
          });
        axios
          .get("https://apiapp.bpmk.sk/api/buildings/" + this.nameApiRequest)
        // .get("http://localhost/OctoberCMS/bpmk_app_api_04/api/buildings/" + this.nameApiRequest)
          .then(({ data }) => {
            this.apiRequest = data;
            this.allPages = Math.ceil(this.apiRequest.length / 2);
            this.allPagesMobile = Math.ceil(this.apiRequest.length / 1);
            this.ResetPaginator();
            this.Loading = false;
          })
          .catch((error) => {
            return this.$router.push("/home/404");
          });
      } else {
        axios
          .get("https://apiapp.bpmk.sk/api/types")
         //.get("http://localhost/OctoberCMS/bpmk_app_api_04/api/types")
          .then(({ data }) => {
            this.NavigatorMenu = data;
          })
          .catch((error) => {
            return this.$router.push("/home/404");
          });
        axios
          .get("https://apiapp.bpmk.sk/api/buildings")
        // .get("http://localhost/OctoberCMS/bpmk_app_api_04/api/buildings")
          .then(({ data }) => {
            this.apiRequest = data;
            this.allPages = Math.ceil(this.apiRequest.length / 2);
            this.allPagesMobile = Math.ceil(this.apiRequest.length / 1);
            this.ResetPaginator();
            this.Loading = false;
          })
          .catch((error) => {
            return this.$router.push("/home/404");
          });
      }
    },
    reset() {
      this.userCardBool = false;
      this.searchInput = "";
    },
  },
  watch: {
    nameApiRequest(newValue) {
      this.Loading = true;
      this.BaseRequest();
    },
    filteredItems(newValue) {
      this.SlideAnimation = true;
      this.ResetPaginator();
      this.LoadingFunction(1000);
    },
  },
  computed: {
    filteredItems() {
      return this.apiRequest.filter((data) => {
        return (
          data.name.toLowerCase().includes(this.searchInput.toLowerCase()) +
          data.place.toLowerCase().includes(this.searchInput.toLowerCase()) +
          data.purpose.name
            .toLowerCase()
            .includes(this.searchInput.toLowerCase()) +
          data.contact.email
            .toLowerCase()
            .includes(this.searchInput.toLowerCase()) +
          data.contact.phone
            .toLowerCase()
            .includes(this.searchInput.toLowerCase()) +
          data.contact.mobile
            .toLowerCase()
            .includes(this.searchInput.toLowerCase())
        );
      });
    },
  },
};
</script>

<style>
</style>
